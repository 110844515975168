import React from "react";
import { HomeSectionContextProvider } from "./src/context/HomeSectionContext";
import { PageContextProvider } from "./src/context/PageContext";

export const wrapRootElement = ({ element }) => (
    <PageContextProvider>
        <HomeSectionContextProvider>
                        {element}
        </HomeSectionContextProvider>
    </PageContextProvider>
);