import React, { createContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const data = graphql`
query HomeSectionQuery {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/home/"}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    nodes {
      html
      frontmatter {
        url
        title
        link
        order
        image {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, formats: WEBP)
          }
        }
      }
    }
  }
} 
`

export const HomeSectionContext = createContext([]);

const HomeSectionContextProvider = ({ children }) => {

  const { allMarkdownRemark } = useStaticQuery(data);


  return (
    <HomeSectionContext.Provider value={{
      homeInfo: allMarkdownRemark.nodes.map(({ frontmatter: { url, title, link, image }, html }) => { return { url, title, link, html, image: image?.childImageSharp } }) || [],
      navbarLinks: allMarkdownRemark.nodes.map(({ frontmatter: { url, link } }) => { return { url, link } }) || [],
    }}>
      {children}
    </HomeSectionContext.Provider>
  )
};

export { HomeSectionContextProvider };
